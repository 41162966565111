import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme, type Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ProCard from 'components/home/ProCard';
import SearchIcon from 'components/icons/SearchIcon';
import NavSearch from 'components/menu/search/NavSearch';
import useTranslation from 'hooks/useTranslation';
import dynamic from 'next/dynamic';
import Logo from 'shared/components/icons/Logo';

const DynamicPreview = dynamic(() => import('components/home/DynamicPreview'), { ssr: false });

const Index = () => {
    const { t } = useTranslation();
    const theme: Theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <>
            <Container
                fixed={true}
                disableGutters={true}
                maxWidth={false}
                sx={{
                    minWidth: '100%',
                    maxWidth: '100vw!important',
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                    alignItems: 'stretch'
                }}
            >
                <Container
                    fixed={true}
                    maxWidth={false}
                >
                    <Stack
                        direction={'column'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        spacing={2}
                        sx={{
                            display: 'flex',
                            flex: 1,
                            minHeight: { xs: 'calc(100vh - 56px)', md: 'calc(100vh - 80px)' },
                            maxWidth: '100%',
                            mb: 5
                        }}
                    >
                        <Box
                            sx={{
                                mb: 4,
                                textAlign: 'center',
                                width: '70%',
                                maxWidth: 400,
                                display: { md: 'none' }
                            }}
                        >
                            <Logo
                                extended={true}
                                color={'primary'}
                            />
                        </Box>
                        <Container maxWidth={'md'} fixed={true} sx={{ mb: 6 }}>
                            <Typography
                                variant={'h1'}
                                align={'center'}
                                sx={{
                                    fontSize: { sm: 44, md: 44 },
                                    fontWeight: { sm: 600 },
                                    color: 'tertiary.main'
                                }}
                            >
                                {t('pages.home.title')}
                            </Typography>
                        </Container>
                        <Container maxWidth={'md'} fixed={true} sx={{ mb: 2 }}>
                            <Typography
                                variant={'body1'}
                                align={'center'}
                                sx={{
                                    fontSize: { xs: 16, sm: 26, md: 26 },
                                    fontFamily: 'new-spirit, Times New Roman, serif'
                                }}>
                                {t('pages.home.subtitle')}
                            </Typography>
                        </Container>

                        <Container maxWidth={'sm'} fixed={true} sx={{ mb: 'auto', display: { md: 'none' } }}>
                            <NavSearch
                                hidden={true}//TODO: recherche
                                SearchInputProps={{
                                    placeholder: t('layout.form.actions.search_variants.index'),
                                    filtersAvailable: false,
                                    StartIcon: SearchIcon
                                }}
                                withSearchFilters={false}
                            />
                        </Container>
                    </Stack>
                </Container>

                <DynamicPreview mobile={mobile} />
                <ProCard />
            </Container>
        </>
    );
};

const getStaticProps = async () => {
    return {
        props: {
            layoutProps: {
                headerType: 'none',
                mobileFooter: true,
                pattern: true,
                withImportVadrouilleContext: true
            }
        }
    };
};

export default Index;
export { getStaticProps };

