import { useTheme } from '@mui/material/styles';

interface IconProps {
    size?: number | string;
}

const SearchIcon = ({ size, ...props }: IconProps) => {
    const theme = useTheme();

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size || 24}
            height={size || 24}
            viewBox="0 0 24 24"
            fill={theme.palette.primary.main}
            {...props}
        >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M10.11,16.3c-.18,0-.36,0-.54-.02-1.9-.14-3.64-1.02-4.88-2.47-1.24-1.45-1.85-3.29-1.71-5.2,.14-1.9,1.02-3.64,2.47-4.88,1.45-1.25,3.29-1.86,5.2-1.71,3.55,.27,6.38,3.15,6.6,6.69,0,.16,.01,.32,.01,.48,0,.16,0,.33-.02,.49h0c-.02,.24-.05,.47-.09,.7-.04,.25-.1,.49-.17,.72-.87,3.07-3.72,5.19-6.88,5.19Zm0-13.31c-3.19,0-5.9,2.47-6.14,5.7-.12,1.64,.4,3.23,1.47,4.48,1.07,1.25,2.57,2,4.21,2.13,2.88,.21,5.6-1.65,6.39-4.45,.06-.2,.11-.41,.14-.62,.03-.2,.06-.4,.08-.61,.01-.14,.02-.28,.02-.42,0-.14,0-.28-.01-.42-.18-3.06-2.63-5.54-5.69-5.77-.16-.01-.31-.02-.47-.02Zm6.64,6.67h0Z"/>
            <path d="M20.5,22c-.15,0-.3-.06-.4-.19l-5.13-5.98c-.19-.22-.16-.56,.06-.75,.22-.19,.56-.16,.75,.06l5.13,5.98c.19,.22,.16,.56-.06,.75-.1,.08-.22,.13-.34,.13Z"/>
            <path d="M14.11,7.43c-.15,0-.3-.08-.37-.22-.21-.38-.47-.73-.78-1.03-.29-.28-.61-.51-.97-.69-.3-.15-.61-.27-.92-.34-.21-.05-.43-.08-.65-.1-.4-.03-.79,0-1.18,.08-.25,.05-.5,.13-.74,.24-.34,.14-.66,.33-.95,.57-.18,.14-.45,.11-.6-.07s-.12-.45,.07-.6c.35-.28,.74-.51,1.14-.68,.29-.12,.58-.22,.89-.28,.47-.1,.95-.14,1.42-.1,.27,.02,.53,.06,.78,.12,.38,.09,.76,.23,1.12,.41,.43,.22,.82,.5,1.17,.83,.37,.36,.69,.78,.94,1.24,.11,.21,.03,.46-.17,.58-.06,.04-.13,.05-.2,.05Z" fill={theme.palette.tertiary.main}/>
        </svg>
    );
};

export default SearchIcon;
export { SearchIcon };

