import { useTheme } from '@mui/material/styles';

interface PatternProps {
    height?: number | string;
    width?: number | string;
    color?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'neutral'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
    | 'disabled';
}

const PatternClaim = ({ color, width = 162, height = 178, ...props }: PatternProps) => {
    const theme = useTheme();

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            fill={
                color == 'inherit'
                    ? 'inherit'
                    : color
                        ? theme.palette[color].main
                        : theme.palette.primary.main
            }
            {...props}
        >
            <path d="M51.05,5.93c-1.62,1.64-20.66,21.42-9.98,36.17,1.03,1.32,2.03,2.57,2.98,3.76,4.79,6.01,8.93,11.2,7.36,12.76-1.68,1.68-7.36-.32-12.36-2.08-1.17-.41-2.36-.83-3.53-1.22-1.14-.38-2.46-.91-3.99-1.52-7.58-3.05-19.03-7.66-29.88-1.7-.58,.32-1.12,.7-1.65,1.1v2.64c.8-.78,1.67-1.46,2.62-1.99,10.01-5.5,20.93-1.1,28.16,1.8,1.56,.63,2.9,1.17,4.1,1.57,1.16,.38,2.33,.8,3.49,1.2,6.37,2.24,11.87,4.17,14.45,1.6,2.82-2.82-.95-7.56-7.21-15.41-.95-1.19-1.94-2.44-2.95-3.72-9.89-13.67,9.68-33.45,9.87-33.64l.14-.17c1.57-2.46,2.36-4.84,2.4-7.1h-2.01c-.04,1.86-.7,3.84-2.02,5.93Z"/>
            <path d="M65.87,0h-3.2c-4.88,4.27-9.77,11.29-16.24,23.01-7.85,14.22,5.38,23.23,12.49,28.07,.89,.61,1.7,1.16,2.38,1.66,6.43,4.72,12.38,12.43,11.3,18.04-.14,.75-.51,1.25-1.15,1.59-2.97,1.55-10.27-1.06-12.78-2.17-1.15-.58-28.33-14.19-43.62-9.67-8.16,2.41-12.56,6.52-15.04,10.73v4.75c1.76-5.05,5.6-10.61,15.61-13.57,14.53-4.29,41.87,9.4,42.19,9.56,1.03,.46,10.22,4.4,14.58,2.14,1.18-.61,1.94-1.64,2.2-2.98,1.29-6.72-5.35-15.09-12.08-20.02-.7-.51-1.53-1.08-2.44-1.7-7.04-4.8-18.83-12.83-11.86-25.46C55.42,10.88,60.56,3.84,65.87,0Z"/>
            <path d="M40.74,1.74c.7,1,.76,2.17,.2,3.58-8.19,11.79-9.53,25.56-9.58,26.15v.07s0,.07,0,.07c0,.06,.3,5.86-2.77,8.38-1.47,1.21-3.57,1.48-6.22,.8-8.89-2.26-14.95-2.43-22.36,2.67v2.45c7.19-5.44,12.64-5.53,21.87-3.19,3.31,.84,6.01,.44,8-1.2,3.67-3.01,3.53-9.12,3.5-9.95,.11-1.05,1.63-14.25,9.28-25.21l.1-.19c.87-2.09,.75-3.97-.36-5.58-.15-.21-.31-.41-.49-.61h-3.24c.89,.5,1.61,1.07,2.07,1.74Z"/>
            <path d="M6.36,1.91c8.74,3.36,12.82,5.93,14.07,8.89,.87,2.05,.51,4.47-1.29,8.65l-.71,1.67q-2.92,6.89-15.98,13.39c-.85,.42-1.66,.81-2.44,1.17v2.21c1.05-.48,2.16-1.01,3.34-1.6,13.47-6.71,13.72-6.83,16.93-14.4l.71-1.66c1.7-3.95,2.57-7.2,1.3-10.21C20.77,6.46,16.51,3.67,7.08,.04c-.04-.02-.08-.03-.12-.04H.13c1.97,.45,4.03,1.06,6.23,1.91Z"/>
            <path d="M10.17,18.25c1.42-4.37-1.52-8.06-1.64-8.22l-.18-.17c-.24-.18-3-2.18-8.35-4.27v2.19c4.17,1.69,6.52,3.25,7.04,3.6,.4,.55,2.2,3.28,1.22,6.27-.86,2.63-3.69,4.89-8.25,6.63v2.13c5.64-2.01,9.06-4.75,10.17-8.17Z"/>
            <path d="M158.59,0c-6.48,4.61-19.4,12.57-32.5,13.6-14.57,1.15-24.65-3.19-28.3-5.09-2.25-1.22-4.77-1.83-7.37-1.77-3.53,.13-8.58,1.5-12.53,7.25-6.88,10.01-8.23,21.62-8.29,22.12-.28,2.85,.86,8.5,7.1,9.56,1.18,.38,19.89,6.66,21.04,23.23,1.18,16.97-7.97,21.6-8.36,21.79l-.2,.13c-3.5,2.83-15.07,6.76-33.76,1.36-10.54-3.05-16.47-3.39-19.24-1.1-2.14,1.77-1.85,4.56-1.6,7.03l.02,.17c.04,.37,.12,.7,.2,1.02,.05,.21,.1,.42,.14,.64,.47,3.22,2.27,14.15,5.84,19.51,4.59,6.88,4.8,12.3,.57,16.17-.06,.06-5.82,6.32-16.74,8.45-11.33,2.21-14.12,5.81-15.33,11.78-.24,2-.28,4.02-.12,6.07,.49,4-.25,7.13-2.28,9.59-1.66,2.01-4.08,3.39-6.85,4.38v2.11c3.36-1.1,6.35-2.73,8.41-5.22,2.37-2.86,3.28-6.6,2.73-11.06-.15-1.87-.11-3.77,.1-5.55,.92-4.55,2.45-7.93,13.73-10.13,11.62-2.27,17.6-8.8,17.78-9.01,3.44-3.14,6.18-8.93-.32-18.68-3.34-5.01-5.08-15.57-5.53-18.69-.04-.28-.11-.55-.17-.83-.06-.25-.12-.5-.15-.75l-.02-.17c-.22-2.16-.43-4.2,.89-5.29,2.11-1.74,7.96-1.24,17.39,1.48,23.55,6.81,34.32-.75,35.51-1.66,1.2-.62,10.61-6.07,9.39-23.67-1.28-18.41-22.3-24.96-22.52-25.02l-.13-.03c-6.01-.98-5.52-7.15-5.5-7.4,.01-.12,1.36-11.62,7.95-21.2,3.48-5.07,7.88-6.27,10.94-6.39,2.21-.04,4.41,.48,6.36,1.54,3.81,2,14.3,6.51,29.41,5.32,15.51-1.22,30.3-11.42,35.75-15.59h-3.41Z"/>
            <path d="M143.47,0c-15.59,11.06-37.09,4.88-43.77,2.52-3.02-1.1-6.19-1.63-9.41-1.58-3.85,.05-7.66,1.07-11.03,2.95-8.91,5.11-11.81,11.47-16.57,23.2-5.1,12.56-.23,17.08,4.57,19.61,4.17,2.2,11.74,9.69,11.81,9.76l.1,.08s3.97,3.08,6.71,7.68c3.58,6.02,3.47,11.71-.33,16.9-9.17,12.55-34.09,.79-34.36,.66-.48-.22-11.75-5.31-18.35-1.31-2.79,1.69-4.31,4.71-4.5,8.96l-.42,7.39c-.04,.48-1,11.9,4.14,22.12,1.49,2.97,1.59,6.34,.28,9.27-1.09,2.43-3.69,5.69-9.84,7.39-1.65,.15-19.62,2.08-22.32,14.04l-.03,.15v.15c.02,.16,.08,1.19-.15,2.7v6.1c2.15-4.23,2.2-7.94,2.17-8.78,2.56-10.73,20.41-12.36,20.6-12.37l.18-.03c6.97-1.9,9.95-5.69,11.23-8.52,1.56-3.47,1.44-7.47-.32-10.98-4.87-9.7-3.94-20.93-3.93-21.08l.42-7.43c.16-3.55,1.35-6.03,3.54-7.35,5.68-3.44,16.36,1.37,16.45,1.41,.27,.13,6.66,3.17,14.38,4.61,10.45,1.94,18.22-.1,22.46-5.9,9.94-13.6-5.66-26.45-6.72-27.3-.67-.67-7.93-7.8-12.25-10.07-4.31-2.27-8.12-6.08-3.65-17.1,5.07-12.49,7.73-17.64,15.7-22.21,3.07-1.71,6.55-2.65,10.07-2.69,2.97-.06,5.89,.44,8.7,1.46,7.25,2.55,31.22,9.4,47.72-4.4h-3.26Z"/>
            <path d="M56.14,27.47c.04-.13,3.63-13.08,12.91-21.39,2.93-2.62,6.68-4.6,10.36-6.07h-4.85c-2.47,1.26-4.84,2.78-6.86,4.59-9.43,8.44-13.12,20.99-13.48,22.27-2.05,4.38-2.64,14.52,9.66,22.1,16.8,10.36,15.23,20.2,15.22,20.29l-.03,.13v.13c.01,.05,.34,5.2-3.31,7.76-3.47,2.43-9.65,1.82-17.86-1.78-14.77-6.47-26.07-7.95-32.95-8.05-5.44-.12-10.67,2.14-14.32,6.18-4.03,4.52-6.02,10.28-5.91,17.11,0,.45,.18,11.18,2.2,17.73,.83,2.69,.27,6.06-1.52,9.25-1.3,2.32-3.16,4.34-5.42,5.97v2.39c3.02-1.91,5.51-4.43,7.17-7.39,2.06-3.66,2.67-7.6,1.68-10.81-1.93-6.28-2.11-17.07-2.11-17.18-.1-6.31,1.72-11.61,5.4-15.75,3.25-3.61,7.9-5.64,12.78-5.51,6.69,.1,17.71,1.55,32.17,7.88,9.02,3.95,15.69,4.48,19.82,1.58,4.26-2.99,4.21-8.49,4.17-9.41,.2-1.36,1.06-11.62-16.15-22.24-13.89-8.56-9.07-19.18-8.86-19.62l.06-.17Z"/>
        </svg>
    );
};

export default PatternClaim;
export { PatternClaim };
