import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { alpha, useTheme, type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import PatternClaim from 'components/patterns/patternClaim';
import useTranslation from 'hooks/useTranslation';


const ProCard = () => {
    const { t } = useTranslation();
    const theme: Theme = useTheme();

    return (
        <Card
            elevation={0}
            color="primary"
            sx={{
                width: '100%',
                backgroundColor: alpha(theme.palette.primary.main, 0.9),
                color: 'primary.contrastText',
                borderRadius: 0,
                position: 'relative',
                ['&:before']: {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'neutral.main',
                    borderRadius: { xs: 0, sm: 2.5 },
                    zIndex: -1
                }
            }}
        >
            <CardContent
                sx={{
                    px: { xs: 2.5, md: 4 },
                    py: { xs: 3, md: 5 }
                }}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        opacity: 0.15,
                        width: '30%',
                        maxWidth: 162,
                        overflow: 'hidden',
                        height: '100%',
                        ['& svg']: {
                            width: '100%',
                            height: 'auto',
                            display: 'block'
                        }
                    }}
                >
                    <PatternClaim color='neutral' />
                </Box>
                <Stack
                    direction={{ xs: 'column', md: 'row' }}
                    spacing={3}
                    justifyContent={{ xs: 'center', md: 'space-around' }}
                    alignItems={'center'}
                    textAlign={'center'}
                    divider={
                        <Divider
                            variant={'middle'}
                            orientation={'vertical'}
                            flexItem={true}
                            sx={{
                                border: 0,
                                width: { xs: '100%', md: '100%' },
                                maxWidth: 375,
                                height: { xs: '1px', md: 'auto' },
                                backgroundColor: alpha('#ffffff', 0.15),
                                alignSelf: { xs: 'center', md: 'stretch' }
                            }}
                        />}
                >
                    <Box
                        sx={{
                            maxWidth: 600,
                            mx: { xs: 'auto', md: 'auto' }
                        }}
                    >
                        <Typography
                            variant={'body1'}
                            align={'center'}
                            sx={{
                                color: 'secondary.main',
                                fontSize: { xs: 18, md: 18 },
                                fontWeight: 600,
                                fontFamily: 'new-spirit, Times New Roman, serif',
                                mb: 4
                            }}
                        >
                            {t('pages.home.contact.title')}
                        </Typography>
                        <Button
                            variant={'contained'}
                            color={'neutral'}
                            size={'large'}
                            href={process.env.NEXT_PUBLIC_PRO_URL || ''}
                            target={'_blank'}
                            sx={{
                                mb: 2
                            }}
                        >
                            {t('pages.home.contact.button')}
                        </Button>
                    </Box>
                </Stack>
            </CardContent>
        </Card>
    );
};

export default ProCard;
export { ProCard };

